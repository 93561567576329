@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,300;1,300&display=swap");

.center {
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.script {
  font-family: monospace;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

/* NAVIGATION */

.navigation {
  display: flex;
  width: 21rem;
  margin: 5em 0 0;
  justify-content: space-between;
}

.navigation img {
  height: 2em;
  width: 2em;
}

@keyframes delayedShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.delayedShow {
  opacity: 0;
  animation-name: delayedShow;
  animation-delay: 15s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.delayedShow2 {
  opacity: 0;
  animation-name: delayedShow;
  animation-delay: 16s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.delayedShow3 {
  opacity: 0;
  animation-name: delayedShow;
  animation-delay: 17s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.navigation img:hover {
  transform: scale(1.3);
}

#li {
  height: 2em;
  width: 2.4em;
}

#twitter {
  height: 2em;
  width: 2em;
}

#twitter:hover {
  transform: scale(1.3);
}

/* MEDIA QUERIES */

@media only screen and (max-width: 800px) {
  .intro {
    font-size: 0.5rem;
  }

  .navigation {
    margin: 3em 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .script {
    font-size: 0.9rem;
  }

  .navigation {
    width: 10rem;
  }
}
